<template>
  <div>
    <BaseForm
      :title="title"
      :mod="mod"
      :flat="flat"
      :divider="divider"
      :dialog="dialog"
      :loading="loading"
      @save="save"
    >
      <template #content>
        <v-card-text>
          <h2>Dados do cliente</h2>
          <v-divider></v-divider>

          <v-row class="mt-2">
            <v-col>
              <v-select
                class="required"
                v-model="form.type"
                :items="[
                  { value: 'physical-person', text: 'Pessoa física' },
                  { value: 'legal-person', text: 'Pessoa jurídica' },
                ]"
                label="Tipo de empresa"
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-text-field
                class="required"
                v-model="form.companyName"
                :label="companyNameLabel"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row v-if="form.type === 'legal-person'">
            <v-col>
              <v-text-field
                class="required"
                v-model="form.fantasyName"
                label="Nome fantasia"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-text-field
                class="required"
                label="CPF/CNPJ: "
                v-model="form.companyTaxDocument"
                v-mask="['###.###.###-##', '##.###.###/####-##']"
                :rules="[rules.taxDocument]"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row v-if="mod === 'insert'">
            <v-col>
              <v-checkbox
                v-model="form.generateAutomaticPassword"
                label="Gerar senha automaticamente ao vincular um anúncio a esta empresa"
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-row v-if="!form.generateAutomaticPassword" class="mb-3">
            <v-col>
              <v-text-field
                accessibilityRole="form"
                label="Nova senha"
                type="password"
                v-model="form.password"
                hide-details
                class="mb-2"
              ></v-text-field>

              <v-progress-linear
                v-if="form.password && form.password.length >= 1"
                :color="passwordScore.color"
                :value="passwordScore.value"
              ></v-progress-linear>

              <v-list
                v-if="
                  form.password &&
                  form.password.length >= 1 &&
                  passwordScore.value < 100
                "
              >
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-circle-small</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content
                    >A senha deve ter pelo menos 8 caracteres
                  </v-list-item-content></v-list-item
                >
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-circle-small</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content
                    >A senha deve ter pelo menos uma letra
                    maiúscula</v-list-item-content
                  ></v-list-item
                >
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-circle-small</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content
                    >A senha deve ter pelo menos uma letra
                    minúscula</v-list-item-content
                  >
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-circle-small</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content
                    >A senha deve ter pelo menos um dígito</v-list-item-content
                  ></v-list-item
                >
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>mdi-circle-small</v-icon>
                  </v-list-item-icon>

                  <v-list-item-content
                    >A senha deve ter pelo menos um caractere
                    especial</v-list-item-content
                  ></v-list-item
                >
              </v-list>
            </v-col>

            <v-col>
              <v-text-field
                accessibilityRole="form"
                label="Confirmar nova senha"
                type="password"
                v-model="form.confirmPassword"
              ></v-text-field>
            </v-col>
          </v-row>

          <h3>Endereço</h3>
          <v-divider></v-divider>

          <v-row class="mt-2">
            <v-col cols="10">
              <v-text-field
                class="required"
                label="Logradouro"
                v-model="form.companyAddress"
                id="address"
                maxlength="254"
              ></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-text-field
                class="required"
                label="Número"
                v-model="form.companyAddressNumber"
                id="address-number"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="8">
              <v-text-field
                class="required"
                label="Bairro"
                v-model="form.companyAddressDistrict"
                id="district"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                label="Complemento"
                v-model="form.companyAddressComplement"
                id="complement"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="mb-3">
            <v-col cols="4">
              <v-text-field
                class="required"
                label="Cep:"
                v-model="form.companyAddressZipCode"
                placeholder="xxxxx-xxx"
                v-mask="'#####-###'"
              ></v-text-field>
            </v-col>
            <v-col cols="8">
              <v-select
                class="required mb-2 mr-sm-2 mb-sm-0"
                :loading="loadingCities"
                v-model="form.CityId"
                :items="cities"
                label="Cidade:"
                id="company-city"
              >
              </v-select>
            </v-col>
          </v-row>

          <h2>Contato</h2>
          <small>Responsável pela empresa</small>
          <v-divider></v-divider>

          <v-row>
            <v-col>
              <v-checkbox
                v-model="form.withoutUser"
                label="Não fornecer os dados do responsável neste momento"
              ></v-checkbox>
            </v-col>
          </v-row>

          <div class="with-user" v-if="!form.withoutUser">
            <v-row>
              <v-col>
                <v-text-field
                  class="required"
                  label="Nome"
                  v-model="form.firstName"
                ></v-text-field>
              </v-col>

              <v-col>
                <v-text-field
                  class="required"
                  label="Sobrenome"
                  v-model="form.lastName"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row class="mb-3">
              <v-col>
                <v-text-field
                  label="E-Mail"
                  v-model="form.email"
                  class="required"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-text-field
                  v-model="form.taxDocument"
                  label="CPF: "
                  v-mask="['###.###.###-##']"
                  :rules="[rules.taxDocument]"
                ></v-text-field>
              </v-col>

              <v-col>
                <v-text-field
                  label="Data de nascimento"
                  v-model="form.dateOfBirth"
                  type="date"
                ></v-text-field>
              </v-col>
            </v-row>

            <h3>Endereço de cobrança</h3>
            <v-divider></v-divider>

            <v-row class="mt-2">
              <v-col>
                <v-checkbox
                  v-model="form.reuseCompanyAddress"
                  label="Utilizar o mesmo endereço já preenchido"
                ></v-checkbox>
              </v-col>
            </v-row>

            <div class="user-address" v-if="!form.reuseCompanyAddress">
              <v-row>
                <v-col>
                  <v-text-field
                    class="required"
                    label="Cep:"
                    v-model="form.addressZipCode"
                    placeholder="xxxxx-xxx"
                    v-mask="'#####-###'"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="10">
                  <v-text-field
                    class="required"
                    label="Logradouro"
                    v-model="form.address"
                    maxlength="254"
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    label="Número"
                    v-model="form.addressNumber"
                    class="required"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="8">
                  <v-text-field
                    class="required"
                    label="Bairro"
                    v-model="form.addressDistrict"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    label="Complemento"
                    v-model="form.addressComplement"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-select
                    v-model="form.addressState"
                    :items="brazilStates"
                    label="Estado:"
                    class="required"
                  >
                  </v-select>
                </v-col>
                <v-col
                  ><v-text-field
                    v-model="form.addressCity"
                    label="Cidade:"
                    class="required"
                  >
                  </v-text-field
                ></v-col>
              </v-row>
            </div>
          </div>
        </v-card-text>
      </template>

      <template #actions></template>
    </BaseForm>
  </div>
</template>

<script>
import formMixin from "@/mixins/formMixin";
import BaseForm from "@/components/template/BaseForm";
import miscellaneous from "@/config/miscellaneous";
import errorHandler from "@/helpers/error_handler";
import { mask } from "vue-the-mask";
import taxDocumentHelper from "@/helpers/tax_document";
import passwordHelper from "@/helpers/password";

export default {
  directives: { mask },
  components: {
    BaseForm,
  },
  mixins: [formMixin],
  data: () => ({
    loading: false,
    loadingCities: false,
    actionAfterOperation: null,
    form: {
      generateAutomaticPassword: false,
      reuseCompanyAddress: false,
      withoutUser: false,
      password: null,
      confirmPassword: null,
    },
    panel: 0,
    cities: [],
    rules: {
      taxDocument: (value) => {
        if (!value) return true;

        if (value.length <= 14) {
          return taxDocumentHelper.checkCPF(value) || "CPF Inválido";
        } else {
          return taxDocumentHelper.checkCNPJ(value) || "CNPJ Inválido";
        }
      },
    },
  }),
  computed: {
    brazilStates() {
      const states = miscellaneous.brazilStates.map((s) => ({
        text: s,
        value: s,
      }));
      return states;
    },
    companyNameLabel() {
      return this.form.type === "legal-person"
        ? "Razão social"
        : "Nome da empresa";
    },
    companyId() {
      return this.$route.params.id;
    },

    passwordScore() {
      const result = passwordHelper.strengthChecker(this.form.password);

      switch (result) {
        case "strong":
          return {
            color: "light-green",
            value: 100,
          };
        case "medium":
          return {
            color: "orange",
            value: 50,
          };
        default:
          return {
            color: "red",
            value: 0,
          };
      }
    },
  },
  methods: {
    validate() {
      const errors = [
        ...this.validateGeneralData(),
        ...this.validateUserAddress(),
        ...this.validateCompanyData(),
        ...this.validadeCompanyAddress(),
      ];

      return errors;
    },
    validateGeneralData() {
      const errors = [];

      if (!this.form.withoutUser) {
        if (!this.form.firstName) errors.push("Informe o nome do cliente");

        if (!this.form.lastName) errors.push("Informe o sobrenome do cliente");

        if (!this.form.email) errors.push("Informe o email do cliente");

        if (
          this.form.password &&
          (!this.form.confirmPassword ||
            this.form.confirmPassword !== this.form.password)
        )
          errors.push("As senhas não conferem");

        if (
          !this.form.generateAutomaticPassword &&
          this.form.password &&
          passwordHelper.strengthChecker(this.form.password) === "weak"
        )
          errors.push("Senha muito fraca");
      }

      return errors;
    },
    validateCompanyData() {
      const errors = [];

      if (!this.form.type) errors.push("Tipo de empresa obrigatório");

      if (!this.form.companyName) errors.push("Nome da empresa obrigatório");

      if (!this.form.companyTaxDocument)
        errors.push("CPF/CNPJ da empresa obrigatório");

      return errors;
    },
    validadeCompanyAddress() {
      const errors = [];

      if (!this.form.CityId) errors.push("Cidade da empresa obrigatória");

      if (!this.form.companyAddress)
        errors.push("Logradouro da empresa obrigatório");

      if (!this.form.companyAddressDistrict)
        errors.push("Bairro da empresa obrigatório");

      if (!this.form.companyAddressZipCode)
        errors.push("CEP da empresa obrigatório");

      return errors;
    },
    validateUserAddress() {
      const errors = [];

      if (!this.form.reuseCompanyAddress && !this.form.withoutUser) {
        if (!this.form.addressZipCode) errors.push("Informe o CEP do endereço");

        if (!this.form.address) errors.push("Informe o logradouro do endereço");

        if (!this.form.addressNumber)
          errors.push("Informe o número do endereço");

        if (!this.form.addressDistrict)
          errors.push("Informe o bairro do endereço");

        if (!this.form.addressCity) errors.push("Informe a cidade do endereço");

        if (!this.form.addressState)
          errors.push("Informe o estado do endereço");
      }

      return errors;
    },
    async save(actionAfterOperation) {
      if (
        !(await this.$root.$confirm("Confirmação", "Salvar dados?", {
          color: "primary",
        }))
      )
        return;

      const errors = this.validate();

      if (errors.length) {
        this.$root.$errorDialog(errors);
      } else {
        this.actionAfterOperation = actionAfterOperation;
        this[this.mod]();
      }
    },
    async insert() {
      try {
        this.loading = true;

        await this.$axios.post(`/admin/customers`, {
          createdFrom: "internally",
          ...this.form,
        });

        this.clearForm();

        this.$toast.success("Dados salvos com sucesso");

        this.afterOperation();
      } catch (error) {
        console.error(error);
        const errorHandled = errorHandler.treatError(error);

        this.$root.$errorDialog(errorHandled);
      } finally {
        this.loading = false;
      }
    },
    async update() {
      try {
        this.loading = true;

        await this.$axios.put(`/admin/customers/${this.companyId}`, this.form);

        this.$toast.success("Dados salvos com sucesso");

        this.loadAdvertiserData();

        this.afterOperation();
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    async loadAdvertiserData() {
      try {
        this.loading = true;

        const res = await this.$axios.get(`admin/customers/${this.companyId}`);

        this.formatAdvertiserData(res.data);
      } catch (error) {
        this.loading = false;
        this.handleErrors(error);
      } finally {
        this.loading = false;
      }
    },
    formatAdvertiserData(data) {
      this.form = { ...data };
    },
    async getCities() {
      try {
        this.loadingCities = true;

        const res = await this.$axios.get("/cities");

        this.setCitiesData(res.data);
      } catch (error) {
        this.handleErrors(error);
      } finally {
        this.loadingCities = false;
      }
    },
    setCitiesData(cities) {
      this.cities = cities.map((c) => {
        return {
          value: c.id,
          text: `${c.name} - ${c.uf}`,
        };
      });
    },
    handleErrors(errors) {
      console.error(errors);

      const errorHandled = errorHandler.treatError(errors);

      this.$root.$errorDialog(errorHandled);
    },
    afterOperation() {
      if (this.actionAfterOperation === "back") this.$router.go(-1);
    },
    clearForm() {
      this.form = {
        generateAutomaticPassword: false,
        password: null,
        confirmPassword: null,
      };
    },
  },
  created() {
    this.getCities();

    if (this.mod === "update") this.loadAdvertiserData();
  },
};
</script>

<style></style>
